import { AppSettings } from "../../config/AppSettings"
import { unitSystemType } from "../../store/redux-types"

export const getUnitsInLabel = (data,type:string) => {
    let copyData = {...data}  
    if(copyData && type=="Mount" && !copyData["Elevation"].label.includes("(")){
      copyData["Height"] = {... copyData["Height"],label:`Height ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["Width"] = {... copyData["Width"],label:`Width ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["Weight"] = {... copyData["Weight"],label:`Weight ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(lb)":"(kg)"} `}
      copyData["Elevation"] = {... copyData["Elevation"],label:`Elevation ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["Standoff"] = {... copyData["Standoff"],label:`Standoff Distance ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
    }
    else if(copyData && type=="Antenna" && !copyData["Elevation"].label.includes("(")){
      copyData["Height"] = {... copyData["Height"],label:`Height ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(in)":"(mm)"} `}
      copyData["Width"] = {... copyData["Width"],label:`Width ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(in)":"(mm)"} `}
      copyData["Weight"] = {... copyData["Weight"],label:`Weight ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(lb)":"(kg)"} `}
      copyData["Elevation"] = {... copyData["Elevation"],label:`Elevation ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["Depth"] = {... copyData["Depth"],label:`Depth ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(in)":"(mm)"} `}
      copyData["LateralOffset"] = {... copyData["LateralOffset"],label:`Lateral Offset ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(mm)"} `}
      copyData["VerticalOffset"] = {... copyData["VerticalOffset"],label:`Vertical Offset ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(mm)"} `}
      copyData["HorizontalOffset"] = {... copyData["HorizontalOffset"],label:`Horizontal Offset ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(mm)"} `}
    
    }
    else if(copyData && (type=="Pole" || type=="Tower") && !copyData["TowerHeight"].label.includes("(")){
      const label = type === "Tower" ? "Tower Height" : "Pole Height";
      copyData["TowerHeight"] = {... copyData["TowerHeight"],label:`${label} ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["TopFaceWidth"] = {... copyData["TopFaceWidth"],label:`Top Face Width ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["TopFaceDepth"] = {... copyData["TopFaceDepth"],label:`Top Face Depth ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["BottomFaceWidth"] = {... copyData["BottomFaceWidth"],label:`Bottom Face Width ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["BottomFaceDepth"] = {... copyData["BottomFaceDepth"],label:`Bottom Face Depth ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
    } 
    else if(copyData && type=="Panel" && !copyData["TopElevation"].label.includes("(")){
      copyData["TopElevation"] = {... copyData["TopElevation"],label:`Top Elevation of Panel ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["BottomElevation"] = {... copyData["BottomElevation"],label:`Bottom Elevation of Panel ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["Height"] = {... copyData["Height"],label:`Height of Panel ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["TopWidth"] = {... copyData["TopWidth"],label:`Width of Panel ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["Weight"] = {... copyData["Weight"],label:`Weight of Panel ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(lb)":"(kg)"} `}
      
    }  
    else if(copyData && type=="MountPipe" && !copyData["Elevation"].label.includes("(")){
      copyData["Elevation"] = {... copyData["Elevation"],label:`Elevation ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["Height"] = {... copyData["Height"],label:`Height ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["LateralOffset"] = {... copyData["LateralOffset"],label:`Lateral Offset ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["VerticalOffset"] = {... copyData["VerticalOffset"],label:`Vertical Offset ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["HorizontalOffset"] = {... copyData["HorizontalOffset"],label:`Horizontal Offset ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
      copyData["OuterDia"] = {... copyData["OuterDia"],label:`Outer Diameter ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(in)":"(mm)"} `}
      copyData["InnerDia"] = {... copyData["InnerDia"],label:`Inner Diameter ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(in)":"(mm)"} `}
    }
    else if(copyData && type=="FlatBar" && !copyData["Depth"].label.includes("(")){
      copyData["Depth"] = {... copyData["Depth"],label:`Depth ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(in)":"(mm)"} `}
      copyData["Width"] = {... copyData["Width"],label:`Width ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(in)":"(mm)"} `}
   }
    else if(copyData && type=="MountMember" && !copyData["Diameter"].label.includes("(")){
      copyData["Diameter"] = {... copyData["Diameter"],label:`Diameter ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(ft)":"(m)"} `}
    }
    return copyData;
  }
  