import { UiFramework } from "@itwin/appui-react";
import { ECSqlReader, QueryRowProxy } from "@itwin/core-common";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { Logger } from "../../api/logging";
import { DTVActions } from "../../../store/Actions";
import { DesignModelData } from "../../../store/States";
import { store } from "../../../store/rootReducer";

export const setDesignModelData = async () => {
  let mountData,antennaData,towerData,panelData,mountPipeData,mountMemberData :any = []
  try {
    // const records: MountData[] = [];
    // for await (const record of iModel!.query(
    //   "select ECClassId, ECInstanceId, ModelName, Elevation, Manufacturer, ModelName, Type, Azimuth, Height, Width, Standoff, Weight from OpenTower.Mount",
    // )) {
    //   records.push(record);
    // }

     mountData = await getRows("select ECClassId, ECInstanceId, ModelName, Classification, Elevation, Location, Manufacturer, ModelName, Type, Azimuth, Height, Width, Standoff, Weight from OpenTower.Mount");

    // records;


    // const arr: any = [];
    // for await (const record of iModel!.query(`select * from OpenTower.TowerInformation`)) {
    //     const data = {
    //         ActiveScenarioID: "",
    //         TowerHeight: record.towerHeight,
    //         TowerType: record.towerType,
    //         NoOfLeg: record.towerType === "Monopole" ? 0 : 3,
    //     };
    //     arr.push(data);
    // }

    // let mountAzimuth;
    // const reader: any = iModel.createQueryReader(`select * from OpenTower.TowerInformation`);
    // const reader: ECSqlReader = iModel.createQueryReader(`select ECClassId, ECInstanceId, ModelName, Elevation, Manufacturer, ModelName, Type, Azimuth, Height, Width, Standoff, Weight from OpenTower.Mount`);
    // const reader: ECSqlReader = iModel.createQueryReader(`SELECT DISTINCT ECClassId, ECInstanceId, Name, Elevation, Manufacturer, ModelName, Type, Azimuth, LateralOffset, VerticalOffset, HorizontalOffset, Height, Width, Depth, Weight FROM OpenTower.Antenna`);
     antennaData = await getRows("SELECT DISTINCT ECClassId, ECInstanceId, Name, Classification, Elevation, Location, Manufacturer, ModelName, Type, Azimuth, LateralOffset, VerticalOffset, HorizontalOffset, Height, Width, Depth, Weight FROM OpenTower.Antenna");
    // const antennaData = await getRows("SELECT * FROM OpenTower.Antenna");
    
     towerData = await getRows("select ECClassId, ECInstanceId, TowerName, BearingAngle, TowerType, TowerHeight, TopFaceWidth, TopFaceDepth, BottomFaceWidth, BottomFaceDepth, NoofPanel from OpenTower.TowerInformation");
    
     panelData = await getRows("select ECClassId, ECInstanceId, Model, FederationGuid, Category, Yaw, Pitch, Roll, Name, PanelID, Height, TopWidth, TopElevation, BottomElevation, FaceBracing, PlanBracing, HipBracing, NumberOfSegments, Weight, ComponentName from OpenTower.Panel");
    
    // globalThis.recsTow = reader;

    // while (await reader.step()) {
    // }

    // // const towReader: ECSqlReader = iModel.createQueryReader(`SELECT DISTINCT ECClassId, ECInstanceId, Name, Elevation, Manufacturer, ModelName, Type, Azimuth, LateralOffset, VerticalOffset, HorizontalOffset, Height, Width, Depth, Weight FROM OpenTower.Antenna`);
    // globalThis.recsTow = reader;

    // while (await reader.step()) {
    // }


    // while (await reader.step()) {
    //   const currentName = reader.current.name;
    //   const description = reader.current.description || "";
    //   if (currentName !== lastClass) {
    //     if (lastClass)
    //       catalog.push({ id: lastClass, label: lastClass, sublabel: lastDescription, subItems });
    //     lastClass = currentName;
    //     lastDescription = description;
    //     subItems = [];
    //   }
    //   const id = catalogName.code.value ? defManager.tryGetEquipmentDefinitionId(catalogName.code.value, reader.current.codeValue) : reader.current.codeValue;
    //   subItems.push({ id, label: currentName, sublabel: reader.current.codeValue, subItems: [] });
    // }


    /* const getMountID = `select ECInstanceId from OpenTower.MOUNT where userLabel = '${mountName}'`;*/
    // for await (mountAzimuth of iModel.query(`select azimuth from OpenTower.Mount where ECInstanceId = ${mountID}`)) {
    // }
    // const getAntennaID = `select TargetECInstanceId from OpenTower.MOUNT_HAS_ANTENNA where SourceECInstanceId = ${mountID}`;
    // for await (const record of iModel.query(`select * from OpenTower.Antenna where ECInstanceId in ( ${getAntennaID} )`)) {
    // for await (const record of iModel.createQueryReader(`select * from OpenTower.Antenna`)) {
    //     records.push(record);
    // }
    // return MFADataApi.formatAntennaJSON(records, mountAzimuth.azimuth);

  } catch (e) {
    Logger.error("Error when fetching Mount data",e);
  }
  try{
    mountPipeData = await getRows("SELECT ECInstanceId, ComponentName, Elevation, Height, OuterDia, InnerDia, Thickness, HorizontalOffset, VerticalOffset, LateralOffset, Type,bBoxHigh,bBoxLow, Name, manufacturer, modelName, location, pitch, roll, userLabel, weight, yaw, MountRefId, AntennaRefId from OpenTower.MountPipe");
  }catch(e){
    Logger.error("Error when fetching Mount Pipe data",e);
  }
  try{
    mountMemberData = await getRows("SELECT ECInstanceId,ComponentName,MountRefId,Diameter,Section,Type,PhysicalMaterial,StartPoint,EndPoint,Location,Parent from OpenTower.MountMember");
  }catch(e){
    try{
      mountMemberData = await getRows("SELECT ECInstanceId,ComponentName,MountRefId,Diameter,Section,Type,PhysicalMaterial,Location,Parent from OpenTower.MountMember")
    }catch(e){
      Logger.error("Error when fetching Mount Member data",e);
    }
  }

  const dmData: DesignModelData = {towerData: towerData as [], mountData: mountData as [], antennaData: antennaData as [], panelData: panelData as [], mountPipeData:  mountPipeData as [], mountMemberData:mountMemberData as []};
  store.dispatch(DTVActions.setDesignModelData(dmData));
}

export const getRows = async (sql: string): Promise<QueryRowProxy[]> => {
    const iModel = UiFramework.getIModelConnection()!;
    const rows: QueryRowProxy[] = [];
    if(!iModel) return [];

    const reader: ECSqlReader = iModel.createQueryReader(sql);
    while (await reader.step()) {
        
        rows.push(reader.current.toRow());
    }  
    return rows;
}