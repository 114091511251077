import { Button, Tab, Tabs } from "@itwin/itwinui-react";
import React, { useState, useEffect } from "react";
import { store } from "../../../store/rootReducer";
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import AddMountElevationPopup from "../tools/AddMountElevationPopup";
import {
  init_DM_MountPropListState,
  mountDMPropList,
  ObjectCategoryTypes,
} from "../../../store/detectedData/apiDataTypes";
import { getValueInRequiredUnit } from "../Tables/BaseTable";
import { ReduceDecimalNumberToTwoPlaces } from "../Lists/EquipmentList";
import { AppSettings } from "../../../config/AppSettings";
import { SyncUiEventIds, unitSystemType } from "../../../store/redux-types";
import { getUnitsInLabel } from "../../tools/getPropertiesUnitLabelled";

export const MembersTabJSX = () => {
  const [index, setIndex] = useState(0);
  const [memberId, setMemberId] = useState("");
  const [parentMount, setParentMount] = useState("");
  const [parentMountTabDisabled, setParentMountTabDisabled] = useState(false);
  const [wholeData, setWholeData] = useState<any>({});
  useEffect(() => {
    const imc = UiFramework.getIModelConnection();
    imc?.selectionSet.emptyAll();
    let objectId =
      store.getState().detectedData.selectedObjectInformation.objectProperties
        .objectIds;

    if (index == 1) {
      let selectedMember: any = store
        .getState()
        .dtvState.designModelData?.mountMemberData?.filter(
          (member) => member.ECInstanceId == objectId[0]
        );
      let allSelectedMember: any = store
        .getState()
        .dtvState.designModelData?.mountMemberData?.filter(
          (member) => member.Parent.Id == selectedMember[0].Parent.Id
        );
      let record: any = [];
      let allRecords = allSelectedMember;

      if (wholeData && wholeData.hasOwnProperty(selectedMember[0].Parent.Id)) {
        imc?.selectionSet.add([...wholeData[selectedMember[0].Parent.Id]]);
      } else {
        if (allRecords) {
          let length = allRecords.length - 1;
          for (let ind = 0; ind < allRecords.length - 1; ind++) {
            if (ind != length && ind < length && ind < 49) {
              record.push(allRecords[ind].ECInstanceId);
              record.push(allRecords[length].ECInstanceId);
              --length;
            } else {
              record.push(allRecords[ind].ECInstanceId);
              break;
            }
          }
        }
        imc?.selectionSet.add([...record]);
      }
      setParentMount(selectedMember[0].Parent.Id);
      if (record && record.length != 0) {
        let copyWholeData = { ...wholeData };
        copyWholeData[selectedMember[0].Parent.Id] = record;
        setWholeData(copyWholeData);
      }
      SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(
        SyncUiEventIds.DM_Mount_Selected
      );
    } else {
      if (
        store.getState().detectedData.selectedObjectInformation.objectProperties
          .objectIds[0]
      ) {
        imc?.selectionSet.add(memberId);
        setMemberId(objectId[0]);
      }

    }
    setParentMountTabDisabled(false);
  }, [index]);
  useEffect(() => {
    if (
      store.getState().detectedData.selectedObjectInformation.objectProperties
        .objectCategoryType != ObjectCategoryTypes.MountMember_DM
    ) {
      setIndex(0);
      setMemberId("");
      setParentMountTabDisabled(false);
    }
  }, [
    store.getState().detectedData.selectedObjectInformation.objectProperties
      .objectCategoryType,
  ]);


  useEffect(() => {
  
    if (
      store.getState().detectedData.selectedObjectInformation.objectProperties
        .objectIds &&
      store.getState().detectedData.selectedObjectInformation.objectProperties
        .objectIds.length
    ) {
      let selectedMember: any = store
        .getState()
        .dtvState.designModelData?.mountMemberData?.filter(
          (member) =>
            member.ECInstanceId ==
            store.getState().detectedData.selectedObjectInformation
              .objectProperties.objectIds[0]
        );
      let obj = store
        .getState()
        .detectedData.selectedObjectInformation.objectProperties.objectIds.find(
          (ids) => ids == memberId
        );
  
      if (
        selectedMember &&
        selectedMember.length &&
        selectedMember[0]?.hasOwnProperty("Parent") &&
        selectedMember[0]?.Parent?.Id != parentMount
      ) {
        setIndex(0);
        setMemberId(
          store.getState().detectedData.selectedObjectInformation
            .objectProperties.objectIds[0]
        );
        setParentMountTabDisabled(false);
      } else {
        if (
          selectedMember &&
          selectedMember.length &&
          !selectedMember[0]?.hasOwnProperty("Parent")
        )
          setParentMountTabDisabled(true);

      }
    }
  }, [
    store.getState().detectedData.selectedObjectInformation.objectProperties
      .objectIds,
  ]);


  const handleMount = () => {
    UiFramework.content.dialogs.open(
      <AddMountElevationPopup
        data={UiFramework.getIModelConnection()}
        type={"edit"}
      />,
      "AddNewRad"
    );
  };

  const parentMountJsx = () => {
    return (
      <>
        <div className="pt-2 propertyGridButton">
          <Button
            className={"property-button"}
            onClick={() => {
              handleMount();
            }}
          >
            Edit Mount
          </Button>
        </div>
        <div></div>
      </>
    );
  };
  return (
    <>
      {store.getState().detectedData.selectedObjectInformation.objectProperties
        .objectCategoryType == ObjectCategoryTypes.MountMember_DM && (
        <>
          <Tabs
            tabsClassName="equipment-posrot-tab"
            contentClassName="pl-0 pb-0 pt-2"
            wrapperClassName="equipment-posrot-tab-wrapper"
            orientation="horizontal"
            onTabSelected={setIndex}
            activeIndex={index}
            type="pill"
            labels={[
              <Tab key={0} label="Member Property" />,
              <Tab
                key={1}
                label="Parent Property"
                disabled={parentMountTabDisabled}
              />,
            ]}
          >
            {index == 1 && parentMountJsx()}
          </Tabs>
        </>
      )}
    </>
  );
};

export const getMountFromMembereID = () => {
  SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(
    SyncUiEventIds.DM_Mount_Selected
  );
  let objectId =
    store.getState().detectedData.selectedObjectInformation.objectProperties
      .objectIds;
  if (objectId && objectId[0]) {
    objectId = objectId[0];
    let selectedMember: any = store
      .getState()
      .dtvState.designModelData?.mountMemberData?.filter(
        (member) => member.ECInstanceId == objectId
      );
    let selectedMount: any = store
      .getState()
      .dtvState.designModelData?.mountData?.filter(
        (mount) => mount.ECInstanceId == selectedMember[0].Parent.Id
      );

    let found = selectedMount;
    Object.keys(found[0]).forEach(function (key) {
      if (!isNaN(found[0][key])) {
        //checking whether number is integer or float
        if (key.toLowerCase().includes("weight"))
          found[0] = {
            ...found[0],
            [key]:
              AppSettings.defaultDisplaySettings.unitSystem ==
              unitSystemType.IMPERIAL
                ? found[0][key]
                : (found[0][key] / 2.2046).toFixed(2),
          };
        else if (noPropertyChange(key.toLowerCase()))
          found[0] = {
            ...found[0],
            [key]: ReduceDecimalNumberToTwoPlaces(found[0][key]).toString(),
          };
        else if (key.toLowerCase().includes("modelname"))
          found[0] = { ...found[0], [key]: found[0][key] };
        else
          found[0] = {
            ...found[0],
            [key]: getValueInRequiredUnit(found[0][key], true),
          };
      }
    });
    let mountPropList: mountDMPropList = getUnitsInLabel(
      init_DM_MountPropListState,
      "Mount"
    );
    const nvPair: any[] = Object.entries(mountPropList).map((e) => ({
      name: e[1].label,
      value: found[0][e[0]],
    }));
    return nvPair;
  }
  return [];
};

const noPropertyChange = (name) => {
  let check = false;
  if (name.includes("azimuth")) check = true;
  else if (name.includes("noofpanel")) check = true;
  else if (name.includes("bearingangle")) check = true;

  return check;
};
